var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"font-family":"'Lato'"}},[_c('q-header',{staticClass:"bg-web text-black"},[_c('q-toolbar',{staticClass:"row justify-center q-px-none"},[_c('div',{staticClass:"row col-12 justify-between"},[_c('div',{staticClass:"row justify-center col-3 xs-hide sm-hide"},[_c('div',{staticClass:"col-xs-9 col-sm-9 col-md-8 col-lg-8 col-xl-6 row justify-center"},[_c('img',{staticClass:"img-fluid cursor-pointer q-my-md",staticStyle:{"height":"80px !important","width":"auto !important"},attrs:{"src":require("../../assets/Header/logo_blanco.png")},on:{"click":function($event){return _vm.routerGo('Home')}}})])]),_c('div',{staticClass:"row justify-center items-center col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"},[_c('div',{staticClass:"row col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 justify-end"},[_c('div',{class:'row items-center  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm
                    ? 'justify-center'
                    : ' justify-end')},[_c('div',{class:'col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 row items-center ' +
                    (_vm.$q.screen.xs || _vm.$q.screen.sm
                      ? 'justify-center'
                      : ' justify-end')},[_c('div',{staticClass:"col-xs-8 col-sm-8 col-md-2 col-lg-2 col-xl-2 q-pa-xs text-center cursor-pointer",style:(_vm.$q.screen.xs || _vm.$q.screen.sm
                      ? ''
                      : 'border-right: solid; border-right-color: white; border-right-width: 1px;'),on:{"click":function($event){return _vm.win.open(                        
                      'https://docs.quilpue.cl/web/media/documentos/2024/04/cuenta-publica-2023-rev-6.pdf',
                      '_blank'
                    )}}},[_c('div',{staticClass:"col-12 row justify-center text-center"},[_c('label',{staticClass:"full-width cursor-pointer",staticStyle:{"font-size":"14px","color":"white"}},[_vm._v(" Cuenta Pública ")]),_c('label',{staticClass:"full-width cursor-pointer",staticStyle:{"font-size":"14px","color":"white","font-family":"'Lato'","font-weight":"700"}},[_c('b',[_vm._v("Versión Completa")])])])]),_c('div',{staticClass:"col-xs-8 col-sm-8 col-md-2 col-lg-2 col-xl-2 q-pa-xs text-center cursor-pointer",style:(_vm.$q.screen.xs || _vm.$q.screen.sm
                      ? ''
                      : 'border-right: solid; border-right-color: white; border-right-width: 1px;'),on:{"click":function($event){return _vm.win.open(
                      'https://docs.quilpue.cl/web/media/documentos/2024/04/cuenta-publica-2023-web.pdf',
                      '_blank'
                    )}}},[_c('div',{staticClass:"col-12 row justify-center text-center"},[_c('label',{staticClass:"full-width cursor-pointer",staticStyle:{"font-size":"14px","color":"white"}},[_vm._v(" Cuenta Pública ")]),_c('label',{staticClass:"full-width cursor-pointer",staticStyle:{"font-size":"14px","color":"white","font-family":"'Lato'","font-weight":"700"}},[_c('b',[_vm._v("Versión Revista")])])])]),_c('div',{staticClass:"col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3 q-pa-xs text-center cursor-pointer"},[_c('q-btn',{staticClass:"icon q-my-sm q-mx-xs",attrs:{"round":"","size":"sm","icon":"ion-logo-youtube"},on:{"click":function($event){return _vm.win.open(
                        'https://www.youtube.com/user/quilpuemunicipalidad/videos',
                        '_blank'
                      )}}},[_c('q-tooltip',[_vm._v(" Youtube ")])],1),_c('q-btn',{staticClass:"icon q-my-sm q-mx-xs",attrs:{"round":"","size":"sm","icon":"ion-logo-twitter"},on:{"click":function($event){return _vm.win.open('https://x.com/muni_quilpue', '_blank')}}},[_c('q-tooltip',[_vm._v(" Twitter ")])],1),_c('q-btn',{staticClass:"icon q-my-sm q-mx-xs",attrs:{"round":"","size":"sm","icon":"ion-logo-instagram"},on:{"click":function($event){return _vm.win.open(
                        'https://www.instagram.com/quilpueciudad/',
                        '_blank'
                      )}}},[_c('q-tooltip',[_vm._v(" Instagram ")])],1),_c('q-btn',{staticClass:"icon q-my-sm q-mx-xs",attrs:{"round":"","size":"sm","icon":"ion-logo-facebook"},on:{"click":function($event){return _vm.win.open(
                        'https://www.facebook.com/MuniQuilpue/',
                        '_blank'
                      )}}},[_c('q-tooltip',[_vm._v(" Facebook ")])],1)],1)])])])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }